    @import '../../../styles/Variables.scss';
    
  .listItem {
    border-radius: 10px;
    padding: 0 !important;
    font-size: 1rem;
    margin-bottom: 10px;
    :hover{
      border-radius: 10px;
    }
    .css-zr3blz-MuiButtonBase-root-MuiListItemButton-root {
      border-radius: 10px !important;
    }

  }