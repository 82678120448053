@import '../../styles/Variables.scss';

.custom_button {
	background-color: $dark;
	color: $white;
	font-size: 20px;
	padding: 10px 60px;
	border-radius: 10px;
	margin: 10px 0px;
	border-width: 1px;
	cursor: pointer;
	font-weight: bold;
}
