@import "../../styles/Variables.scss";

.navContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $gray-light;
    width: '100%';
    overflow: 'hidden'
}
.logoutContainer {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 1em;
    position: 'absolute';
    background-color: $gray-light;
  };

.logoutButton {
    color: $red;
    cursor: pointer;
    border-radius: "50%";
    height: 20px;
    width: 20px;
}

.drawerContainer {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px !important;
}

.userName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $dark;
    font-size: $font-size-12px !important;
}

.listContainer {
  height: 92vh;
  border: 1px solid red;
}
