$width-sm: 576px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;
$width-xxl: 1440px;

$window-width-sm-max: 767px;

$background-color-primary: #FFFFFF;
$background-color-secondary: #FCFCFC;
$background-color-third: #DCDCDC;
$background-color-fourth: #EDEDED;

$plus-jakarta: 'Plus-Jakarta-Sans';
$plus-jakarta-medium: 'Plus-Jakarta-Sans-Medium';
$plus-jakarta-bold: 'Plus-Jakarta-Sans-Bold';

$info-color: #00599e;
$info-color-border: darken($info-color, 10%);
$info-color-border-hover: lighten($info-color-border, 10%);
$info-color-hover: lighten($info-color, 10%);
$info-color-shadow: darken($info-color, 10%);

$font-size-11px: 0.688em;
$font-size-12px: 0.75em;
$font-size-14px: 0.875em;
$font-size-16px: 1em;
$font-size-18px: 1.125em;
$font-size-20px: 1.25em;
$font-size-22px: 1.375em;
$font-size-24px: 1.5em;
$font-size-26px: 1.625em;
$font-size-28px: 1.75em;
$font-size-30px: 1.875em;
$font-size-34px: 2em;
$font-size-36px: 2.25em;
$font-size-48px: 3em;
$font-size-52px: 3.25em;
$font-size-56px: 3.5em;
$font-size-60px: 3.75em;
$font-size-70px: 4.375em;

$white: #FFFFFF;
$dark: #000000;
$text-dark: #302E2E;
$text-lighter: #A3A5A9;

$borderColor: #C2C2C2;
$sideNavBGcolor: #1677ff;

$gray: #979797;
$gray-dark: #707070;
$gray-text: #A9A9A9;
$gray-light: #ecebeb;

$red: #EC0009;