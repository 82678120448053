.header {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #f4f4f4;
}

.logo {
	width: 50px;
	height: auto;
	margin-bottom: 20px;
}

.text {
	margin-bottom: 20px;
	text-align: center;
	font-size: 40px;
	color: black;
	margin-left: 20px;
	font-weight: bold;
}

.content {
	width: 500px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
	padding: 50px;
	text-align: center;
	background-color: white;
	border-radius: 16px;
}

a:active {
	color: #000000 !important;
}
