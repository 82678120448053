@import "./styles/Variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: $plus-jakarta;
  src: url('../src/asset/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  src: url('../src/asset/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
}
h1, h2, h3, h4, h5, h6, p, a, div, input, ul, ol, li, span {
font-family: $plus-jakarta,'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $plus-jakarta, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

::placeholder {
  color: $gray-text !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $dark !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $dark !important;
}