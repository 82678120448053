.loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .formItems {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .paddingDiv {
    text-align: left;
    margin-bottom: 20px;
  }
