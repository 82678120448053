@import "../../../styles/Variables.scss";

.title {
    margin-left: .6rem !important;
    color: $dark;
    font-size: $font-size-24px !important;
    font-weight: 700 !important;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    // font-family: $plus-jakarta-bold !important;
  }

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: space-between !important;
}